<template>
  <div class="surface-section w-full md:w-6 p-6 md:p-8">
    <div class="mb-5">
      <div class="text-900 text-3xl font-medium mb-3">Formulaire de changement de mot de passe</div>
    </div>
    <div>
      <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
        <label for="password" :class="{'p-error':v$.password.$invalid && submitted}" class="block text-900 font-medium mb-2"><i class="fa-solid fa-lock" /> {{ $t('password') }} *</label>
        <InputText id="password" v-model="v$.password.$model" :class="{'p-invalid':v$.password.$invalid && submitted}" type="password" class="w-full mb-3 p-3" />
        <div v-if="v$.password.$error && submitted" class="mb-2">
          <span id="password-error" v-for="(error, index) of v$.password.$errors" :key="index">
            <InlineMessage>{{error.$message}}</InlineMessage>
          </span>
        </div>

        <label for="password-confirm" :class="{'p-error':v$.passwordConfirm.$invalid && submitted}" class="block text-900 font-medium mb-2"><i class="fa-solid fa-lock" /> {{ $t('passwordConfirm') }} *</label>
        <InputText id="password-confirm" v-model="v$.passwordConfirm.$model" :class="{'p-invalid':v$.passwordConfirm.$invalid && submitted}" type="password" class="w-full mb-3 p-3" />
        <div v-if="v$.passwordConfirm.$error && submitted" class="mb-2">
          <span id="password-confirm-error" v-for="(error, index) of v$.passwordConfirm.$errors" :key="index">
            <InlineMessage>{{error.$message}}</InlineMessage>
          </span>
        </div>

        <Button :label="$t('changePassword')" type="submit" icon="fa-regular fa-user" class="w-full p-3"></Button>
      </form>
    </div>
<!--    <div style="position: fixed; bottom: 20px; left: 20px;">-->
<!--      <language-selection />-->
<!--    </div>-->
  </div>
  <div class="hidden md:block w-6 bg-no-repeat bg-cover" :style="{ backgroundImage: 'url(' + require('@/assets/images/signin/signin.jpg') + ')' }"></div>
</template>

<script>
// import LanguageSelection from '@/components/LanguageSelection';
import Alert from '@/utils/Alert';
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";


export default {
  setup: () => ({ v$: useVuelidate() }),
  components : {
    // LanguageSelection,
  },
  data () {
    return {
      password: '',
      passwordConfirm: '',
      submitted: false
    }
  },
  async created () {
    this.$store.state.misc.loading = true
    const response = await this.$store.dispatch('auth/checkReset', { token: this.$route.params.token })
    if (response.data !== true) {
      this.$toast.add({severity:'error', summary:'Erreur', detail:'Veuillez vérifier vos informations', life: 3000});
      this.$store.state.misc.loading = false
      this.$router.push({ name: 'Login' })
    }
    this.$store.state.misc.loading = false
  },
  validations() {
    return {
      password: {
        required: helpers.withMessage('Mot de passe requis', required),
      },
      passwordConfirm: {
        required: helpers.withMessage('Confirmation de mot de passe requise', required),
        mustMatchPassword: helpers.withMessage('Les mots de passe ne correspondent pas', (value) => value === this.password),
      },
    }
  },
  methods: {
    async handleSubmit (isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        Alert.errorMessage(this, 'fieldRequired', {symbole: '(*)'})
        return
      }

      this.$store.state.misc.loading = true

      const payload = {
        password: this.password,
        token: this.$route.params.token
      }

      await this.$store.dispatch('auth/update', payload)
        .catch((e) => {
          Alert.errorMessage(this, e.response.data.message)
          this.resetForm()
        })

      this.$toast.add({severity:'success', summary:'Succès', detail:'Mot de passe changé avec succès', life: 3000});
      this.$store.state.misc.loading = false
      this.$router.push({ name: 'Login' })
    },
    resetForm () {
      this.password = ''
      this.passwordConfirm = ''
      this.submitted = false
    }
  }
}
</script>

<style lang="less" scoped>
</style>
